import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import { FilePresent, Flag } from '@mui/icons-material';

interface Props extends IconButtonProps {
    tooltipLabel?: string;
    imageStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

const iconStyle = {
    padding: 0.7,
    // borderRadius: 'unset',
    borderStyle: 'solid',
    borderColor: 'inherit',
}

const TableViewButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FEF9DB',
        color: '#D99800'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                sx={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Visibility fontSize='inherit' htmlColor="inherit" />
            </IconButton>
        </Tooltip >
    )
});

const TableEditButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#c5cae9',
        color: '#303f9f'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? "Edit"}>
            <IconButton
                size="small"
                sx={{ ...iconStyle, ...style }}
                {...rest}
            >
                <EditIcon fontSize='inherit' htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const CustomTableButton = React.memo((props: Props & { children: JSX.Element }) => {
    const { tooltipLabel, children, style = {
        background: '#f5f5f5',
        color: '#212121'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                sx={{ ...iconStyle, ...style }}
                {...rest}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
});

const TableDeleteButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#FF0606"
    }, imageStyle, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="secondary"
                {...rest}
                sx={{ ...iconStyle, ...style }}
            >
                <DeleteIcon fontSize='inherit' htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const TableFlagButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#F8F8F8',
        color: "#00b173"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                sx={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Flag fontSize='inherit' htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const TableVisaButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#C20000"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                sx={{ ...iconStyle, ...style }}
                {...rest}
            >
                <FilePresent fontSize='inherit' htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const TablePermissionButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FEF9DB',
        color: "#F6AC00"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                sx={{ ...iconStyle, ...style }}
                {...rest}
            >
                <LockIcon htmlColor='inherit' />
            </IconButton>
        </Tooltip>
    )
});



export {
    TableViewButton,
    TableEditButton,
    TableDeleteButton,
    TableFlagButton,
    TableVisaButton,
    CustomTableButton,
    TablePermissionButton
};