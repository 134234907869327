import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CustomModal } from 'components';

const DeleteForm: React.FunctionComponent<any> = (props): JSX.Element => {
  const { onHide, show, detailData } = props;
  // const { executeRecaptcha } = useGoogleReCaptcha() as any;

  const { validation, deleteResponse } = useSelector(
    (state: any) => ({
      deleteResponse: state.expenseItem.deleteResponse,
      validation: state.expenseItem.validation,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (deleteResponse) {
      onHide();
    }
  }, [deleteResponse]);

  const methods = useForm<any>({
    mode: 'all',
    defaultValues: {
      id: null,
      full_name: '',
      phone: '',
      email: '',
      address: '',
      court_resource_id: null,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  // const onSubmit = React.useCallback(
  //   (data :any) => {
  //     if (!executeRecaptcha) return;
  //     (async () => {
  //       try {
  //         const token = await executeRecaptcha('expensePrice');
  //         data.id = detailData?.id;
  //         data.captcha_token = token;
  //         // dispatch(expenseItemActions.deleteExpenseItem(data));
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     })();
  //   },
  //   [executeRecaptcha],
  // );

  useEffect(() => {
    if (validation) {
      Object.keys(validation).map(err => {
        setError(err, {
          type: 'manual',
          message: validation[err],
        });
      });
    }
  }, [validation]);

  return (
    <>
      <CustomModal
        open={show}
        onClose={onHide}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="modal-wrap">
          <div className="modal-content">
            <div className="modal-body mt-3">
              <form 
              // onSubmit={handleSubmit(onSubmit)} 
              className="form-wrap row">
                <div className="col-md-12">
                  {/* <TextArea
                    required
                    containerClassName="form-group"
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    {...register('remarks', {
                      required: 'Required',
                    })}
                    error={errors.remarks}
                  /> */}
                </div>
                <div className="col-md-12">
                  <div className="alert alert-danger" role="alert">
                    Once you delete , it cannot be reverted.
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      name="is_blacklist"
                      className="custom-control-input"
                      id="is_blacklist"
                      ref={register}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="is_blacklist"
                    >
                      Blacklist this phone number
                    </label>
                  </div>
                </div> */}
                <div className="col-md-12 modal-footer">
                  <button
                    type="submit"
                    className="btn btn-danger ripple"
                    style={{ background: 'red' }}
                  >
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default DeleteForm;
