import { memo } from 'react';
import moment from 'moment';
import { Close } from '@mui/icons-material';

export interface Props {
  label: string;
  item: any;
  removeFilter: any;
}
const RenderFitlerTagLabel = memo(({ label, item, removeFilter }: Props) => {
  if (label == 'from_date' || label == 'to_date') {
    return (
      <div
        className="border bg-dark filter-tag d-inline-flex align-items-center text-white py-1 px-2 rounded mr-1 my-1"
        // style={{ color: '#f9ab16' }}
      >
        {moment(item).format('YYYY-MM-DD')}
      </div>
    );
  } else {
    return (
      <div
        className="border bg-dark filter-tag d-inline-flex align-items-center py-1 px-2 text-white rounded mr-1 my-1"
        // style={{ color: '#f9ab16' }}
      >
        {item.label || item}{' '}
        <button
          className="btn btn-sm btn-dark d-flex justify-content-center align-items-center text-white rounded-circle ml-2 p-0"
          onClick={removeFilter}
        >
          <Close />
        </button>
      </div>
    );
  }
}) as any;

RenderFitlerTagLabel.defaultProps = {
  removeFilter: () => null,
};

export { RenderFitlerTagLabel };
