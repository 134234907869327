import { Grid, Typography } from "@mui/material";
import { CustomModal } from "components";
import SectionLayout from "layouts/SectionLayout";
import useResponsive from "hooks/useResponsive";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedData: any;
  headerText: string;
}

const View = (props: Props) => {
  const { onClose, open, selectedData, headerText } = props;
  const renderValue = (name: string, value: string) => {
    return (
      <span style={{ display: "flex", justifyContent: "flex-start" }}>
        <Typography variant="subtitle2" style={{ marginRight: 10 }}>
          {name} :{" "}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </span>
    );
  };
  const isDesktop = useResponsive("up", "lg");

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={headerText}
      size={isDesktop ? "lg" : "xs"}
    >
      <SectionLayout title={'Agent Detail'}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <img src={selectedData?.profile_photo_url} height="150px"/>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {renderValue("Full Name", selectedData?.full_name_search)}
              </Grid>

              <Grid item xs={4}>
                {renderValue("Email", selectedData?.email)}
              </Grid>

              <Grid item xs={4}>
                {renderValue("Phone", selectedData?.phone)}
              </Grid>

              <Grid item xs={4}>
                {renderValue("DOB", selectedData?.dob_ad)}
              </Grid>

              <Grid item xs={4}>
                {renderValue("Gender", selectedData?.gender)}
              </Grid>

              <Grid item xs={4}>
                {renderValue("Language", selectedData?.language)}
              </Grid>
              <Grid item xs={4}>
                {renderValue("Flagged", selectedData?.is_flagged === 1 ? 'Yes':'No')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginY:2 }}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue(
              "Citizenship Number",
              selectedData?.citizenship_number
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue("Issue Date", selectedData?.citizenship_issue_date_ad)}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue("Province", selectedData?.citizenship_province)}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue("District", selectedData?.citizenship_district)}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue(
              "Municipality",
              selectedData?.citizenship_municipality
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue("Tole", selectedData?.citizenship_tole)}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue("Ward", selectedData?.citizenship_ward_no)}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue("Passport Number", selectedData?.passport_number)}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue(
              "Passport Issue Date",
              selectedData?.passport_issue_date_ad
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {renderValue(
              "Passport Expiry Date",
              selectedData?.passport_expiry_date_ad
            )}
          </Grid>
        </Grid>
      </SectionLayout>
    </CustomModal>
  );
};

export default View;
